.App {
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrap{
  text-align: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.particle-container{
  width: 100%;
  height: 100%;
  background-color: black;
}

.content{
  z-index: 1;
  width: fit-content;
  position: absolute;
  color: white;
}
.email{
  -webkit-touch-callout: text;
  -webkit-user-select: text;
   -khtml-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}